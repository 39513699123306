// ===========================================
// BOOTSTRAP'S GRID
// ===========================================

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);
@include _assert-ascending($container-max-widths, "$container-max-widths");

.container {
	@include make-container();
	@include make-container-max-widths();
}

.container-fluid {
	width: 100%;
	@include make-container();
}

.row {
	@include make-row();
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
	> .wrap {
		padding-right: 0;
		padding-left: 0;
	}
}


// ===========================================
// BACE GRID
// ===========================================

html {
	@include prefix('box-sizing', 'border-box', webkit moz ms o);
    -ms-overflow-style: scrollbar;
}

	*,
	*:before,
	*:after {

		@include prefix('box-sizing', 'inherit', webkit moz ms o)
		
	}

	.block { padding: 1em 0; }

	.wrap { @include make-col-ready() }

	.main_container {
		margin: 0 0 1em;
		box-sizing: border-box;
		@include media-breakpoint-up(sm) {
			[class*="col"] { padding: 0.5em; }
			.col-sm-12 { float: left; width: percentage(12/12); }
			.col-sm-11 { float: left; width: percentage(11/12); }
			.col-sm-10 { float: left; width: percentage(10/12); }
			.col-sm-9 { float: left; width: percentage(9/12); }
			.col-sm-8 { float: left; width: percentage(8/12); }
			.col-sm-7 { float: left; width: percentage(7/12); }
			.col-sm-6 { float: left; width: percentage(6/12); }
			.col-sm-5 { float: left; width: percentage(5/12); }
			.col-sm-4 { float: left; width: percentage(4/12); }
			.col-sm-3 { float: left; width: percentage(3/12); }
			.col-sm-2 { float: left; width: percentage(2/12); }
			.col-sm-1 { float: left; width: percentage(1/12); }
		}
		@include media-breakpoint-up(md) {
			.col-md-break { float: none; width: percentage(12/12); }
			.col-md-12 { float: left; width: percentage(12/12); }
			.col-md-11 { float: left; width: percentage(11/12); }
			.col-md-10 { float: left; width: percentage(10/12); }
			.col-md-9 { float: left; width: percentage(9/12); }
			.col-md-8 { float: left; width: percentage(8/12); }
			.col-md-7 { float: left; width: percentage(7/12); }
			.col-md-6 { float: left; width: percentage(6/12); }
			.col-md-5 { float: left; width: percentage(5/12); }
			.col-md-4 { float: left; width: percentage(4/12); }
			.col-md-3 { float: left; width: percentage(3/12); }
			.col-md-2 { float: left; width: percentage(2/12); }
			.col-md-1 { float: left; width: percentage(1/12); }
		}
		@include media-breakpoint-up(lg) {
			.col-lg-break { float: none; width: percentage(12/12); }
			.col-lg-12 { float: left; width: percentage(12/12); }
			.col-lg-11 { float: left; width: percentage(11/12); }
			.col-lg-10 { float: left; width: percentage(10/12); }
			.col-lg-9 { float: left; width: percentage(9/12); }
			.col-lg-8 { float: left; width: percentage(8/12); }
			.col-lg-7 { float: left; width: percentage(7/12); }
			.col-lg-6 { float: left; width: percentage(6/12); }
			.col-lg-5 { float: left; width: percentage(5/12); }
			.col-lg-4 { float: left; width: percentage(4/12); }
			.col-lg-3 { float: left; width: percentage(3/12); }
			.col-lg-2 { float: left; width: percentage(2/12); }
			.col-lg-1 { float: left; width: percentage(1/12); }
		}	
		@include media-breakpoint-up(xl) {
			.col-xl-break { float: none; width: percentage(12/12); }
			.col-xl-12 { float: left; width: percentage(12/12); }
			.col-xl-11 { float: left; width: percentage(11/12); }
			.col-xl-10 { float: left; width: percentage(10/12); }
			.col-xl-9 { float: left; width: percentage(9/12); }
			.col-xl-8 { float: left; width: percentage(8/12); }
			.col-xl-7 { float: left; width: percentage(7/12); }
			.col-xl-6 { float: left; width: percentage(6/12); }
			.col-xl-5 { float: left; width: percentage(5/12); }
			.col-xl-4 { float: left; width: percentage(4/12); }
			.col-xl-3 { float: left; width: percentage(3/12); }
			.col-xl-2 { float: left; width: percentage(2/12); }
			.col-xl-1 { float: left; width: percentage(1/12); }
		}		
	}


	// ==================================================
	// BLOCKS
	// ==================================================

	.banner {

		@include media-breakpoint-up(md) {
			.row { align-items: center; }
			.banner_branding { @include make-col(7) }
			.banner_contact { @include make-col(5) }
		}

		&.has_header_menu {
			@include media-breakpoint-up(lg) {
				.row { align-items: start; }
				.banner_branding {
					@include make-col(4);
					padding-top: 40px;
					padding-bottom: 40px;
				}
				.banner_contact {
					@include make-col(8);
				}
				.banner_menu {
					@include make-col(8);
					margin: -70px 0 0 auto;
				}
			}		
		}


	}

	.jumbo {
		@include media-breakpoint-up(lg) {
			.row { align-items: center }
			.jumbo_marketing { @include make-col(8) }
			.jumbo_conversion { @include make-col(4) }
		}
	}

	.content {
		@include media-breakpoint-up(lg) {
			.main { @include make-col(8) }
			.sidebar { @include make-col(4) }
		}
	}

	.site_info {
		@include media-breakpoint-up(sm) {
			.row { align-items: center; }
			.wrap { @include make-col(6) }
		}
	}
