$banner-bg: 							$white !default;
$banner-color: 							$color-text !default;

.banner {

	background-color: $banner-bg;
	color: $banner-color;

	@include media-breakpoint-up(md) { padding: 2em 0; }
	@include media-breakpoint-up(lg) { padding: 3em 0 3.25em; }

	&.has_header_menu {
		@include media-breakpoint-up(lg) { padding: 2em 0 1.5em; }
	}

}