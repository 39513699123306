.block-main.socials {
	
	position: relative;
	z-index: 5000;
	padding: 5px 0 10px !important;
	text-align: center; 
	background-color: $white;

	p {
		span {
			display: inline-block;
		}
	}
	
	img.soc-img {
		@include transition(all ease-in-out 200ms);
		transform: scale(0.9);
		max-width: 75px;
		margin: 0 auto 5px;
	}

	a.soc-link {
		@include transition(all ease-in-out 200ms);
		display: block;
		margin: 0 auto;
		padding: 0.3em 0 0.5em !important;
		max-width: 480px;
		font-size: 1rem;
		font-style: italic;
		line-height: 1em;
		letter-spacing: -0.0525em;
		text-decoration: none;
		text-transform: uppercase;
		color: theme(primary, dark);
		strong {
			position: relative;
			display: inline-block;
			vertical-align: baseline;
			top: 0.1em;
			font-weight: 800;
		}
		&:hover {
			@include transition(all ease-in-out 200ms);
			img.soc-img { transform: scale(1.0) }
			color: theme(primary, light);
		}
		img.soc-img {
			display: inline-block;
			margin: 0;
			vertical-align: middle;
		}
	}

	@include media-breakpoint-up(md) {
		padding-top: 15px !important;
		.wrap {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: center;
			p {
				display: inline-block;
				width: auto;
				margin: 0;
				&.soc-google {
					margin-right: 60px;
					white-space: nowrap;
				}
			}
		}
	}

}