.choose {

	border-top: 0.25rem solid $white;
	border-bottom: 0.25rem solid $white;
	box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
	color: $white;

	.block_title {

		&:before { content: "Why Choose " }
		&:after { content: "?" }
		
		font-size: 2rem;
		font-weight: 800;
		letter-spacing: -0.02em;
		small { font-weight: 400 }
		@include media-breakpoint-up(md) { font-size: 2.2rem; }
		@include media-breakpoint-up(lg) { font-size: 2.4rem; }
		@include media-breakpoint-up(xl) { font-size: 2.6rem; }

	}

	.bbb_reviews {
		display: inline-block;
		float: right;
	}

	.bbb_reviews_wrap {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 215px;
		height: 205px;
		padding: 10px;
		border-radius: 100px;
		background-color: white;
		@include transform(scale(0.9));
	}

	ul, ol {
		font-size: 1.2rem;
		font-weight: 600;
		line-height: 1.2em;
		li:not(:last-child) {
			margin-bottom: 0.75em;
		}
	}

	@include media-breakpoint-up(lg) {
		.row { justify-content: center; }
		.wrap { @include make-col(10); }
	}

	@include media-breakpoint-up(xl) {
		.wrap { @include make-col(9); }
		ul, ol { font-size: 1.3rem; }
	}

}