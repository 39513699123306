.jumbo {

	position: relative;
	padding: 1em 0;
	background-image: linear-gradient(
		45deg,
		theme(primary, light),
		theme(primary, base),
		theme(primary, dark)
	);
	background-size: cover;
	background-position: center center;
	color: $white;

	@include media-breakpoint-up(md) { background-image: url("/_/images/layout/jumbo-md.jpg") }
	@include media-breakpoint-up(lg) { background-image: url("/_/images/layout/jumbo-lg.jpg") }

}

$jm_highlight: rgba($white, 0.82);

.jumbo_marketing {


	text-align: center;

	h1 {
		margin-bottom: 25px;
		font-size: 2rem;
		line-height: 1em;
		letter-spacing: -0.0575em;
		text-transform: uppercase;
		text-shadow: 0 1px 1px #000;
	}

	p {
		max-width: 590px;
		margin: 0 auto 15px;
		font-size: 1.2rem;
		font-weight: 800;
		letter-spacing: -0.0375em;
		line-height: 1.325em;
		text-shadow: 0 1px 1px #000;
		//span { padding: 3px 0 }
	}

/*	@include media-breakpoint-up(md) {
		span {
			display: inline;
			padding: 2px 0;
			color: theme(text, base);
			background-color: $jm_highlight;
			box-shadow: 10px 0 0 0 $jm_highlight,
						-8px 0 0 0 $jm_highlight;
		}
	}*/


	@include media-breakpoint-up(lg) {
		text-align: left;
		h1 { font-size: 2.5rem; }
		p {
			margin-left: 0;
			font-size: 1.5rem;
			line-height: 1.525em;
		}
	}

	@include media-breakpoint-up(xl) {
		h1 { font-size: 3rem; }
		p {
			max-width: 640px;
			font-size: 1.625rem;
			line-height: 1.425em;
		}
	}

}

.jumbo_conversion {
	@include media-breakpoint-down(sm) {
		.form_title { background-color: theme(secondary, base); }
	}
}