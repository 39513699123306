.banner_branding {

	.branding_logo {
		display: block;
		margin: 0 auto;
		max-width: 234px;
		line-height: 1em;
		text-align: center;
		color: $color-text;
		text-decoration: none;
	}

		.branding_slogan {
			display: block;
			max-width: 200px;
			margin: 0 auto 10px;
			padding: 5px;
			font-size: 14px;
			font-weight: 800;
			line-height: 1em;
			letter-spacing: -0.0275em;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			color: theme(text, base);
			img {
				max-width: 125px;
				margin: 0 auto;
			}
		}

	@include media-breakpoint-up(md) {
		.branding_logo { margin: 0 }
		.branding_slogan {
			display: inline-block;
			margin: 0;
			max-width: none;
			text-align: left;
			img {
				display: inline;
				vertical-align: middle;
			}
		}
	}

}

